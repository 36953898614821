import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Posts from "../components/Posts"
// import PaginationLinks from "../components/PaginationLinks"
import { graphql } from "gatsby"
const CategoryTemplate = props => {
  const {
    //pageContext: { category, currentPage, numberOfPages, limit, skip },
    pageContext: { category },
  } = props
  const {
    data: {
      categories: { nodes: posts },
    },
  } = props
  //const { currentPage, numberOfPages } = props.pageContext

  return (
    <Layout>
      <Hero />
      <Posts posts={posts} title={`category / ${category}`} />
      {/* <PaginationLinks
        category={category}
        currentPage={currentPage}
        numberOfPages={numberOfPages}
      /> */}
    </Layout>
  )
}
export const query = graphql`
  query MyQuery($category: String!) {
    categories: allMdx(
      filter: { frontmatter: { category: { eq: $category } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          category
          readTime
          date(formatString: "MMM Do, YYYY")
          slug
          image
        }
        excerpt
        id
      }
    }
  }
`

// export const query = graphql`
//   query MyQuery($category: String!, $skip: Int!, $limit: Int) {
//     categories: allMdx(
//       filter: { frontmatter: { category: { eq: $category } } }
//       sort: { fields: frontmatter___date, order: DESC }
//       limit: $limit
//       skip: $skip
//     ) {
//       nodes {
//         frontmatter {
//           title
//           category
//           readTime
//           date(formatString: "MMMM Do, YYYY")
//           slug
//           image
//         }
//         excerpt
//         id
//       }
//     }
//   }
//`

export default CategoryTemplate

// {
//   childImageSharp {
//     fluid {
//       ...GatsbyImageSharpFluid
//     }
//   }
// }
